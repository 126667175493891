import { Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { SETTINGS_API } from '../../api/settings';
import Box from '../../components/Box';
import CountriesTable from '../../components/Table/CountriesTable';
import Text from '../../components/Text';
import FadedText from '../../components/Text/FadedText';
import LinkText from '../../components/Text/LinkText';
import PageContainerWithFadedLeftNavigation from '../../containers/PageContainerWithFadedLeftNavigation';
import { INTERNAL_LINKS } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import {
  emitFetchCountryCodeMapping,
  emitFetchGlobalSettings,
  emitUpdateGlobalSettings,
} from '../../stores/actions/systemAdmin';
import { formatPageTitle } from '../../utils/common';
import { normalizeGlobalSettings } from '../../utils/settings';
import DefaultMappingSettingForm from './DefaultMappingSettingForm';
import DefaultMapViewProviderForm from './DefaultMapViewProviderForm';
import DomainsSection from './DomainsSection';
import FreeTrialSettingsSection from './FreeTrialSettingsSection';
import GlobalPayrollFilSettingsSection from './GlobalPayrollFileSettingsSection';
import MetabaseReportsSection from './MetabaseReportsSection';
import PasswordSettingSection from './PasswordSettingSection';
import TimezonesSection from './TimezonesSection';
import TripsSection from './TripsSections';

const GlobalSettingsView = props => {
  const { t, history, location } = props;

  const dispatch = useDispatch();
  const { globalSettings, countryCodeMappings } = useSelector(store => store.systemAdmin);

  useEffect(() => {
    if (!countryCodeMappings.length) {
      dispatch(emitFetchCountryCodeMapping());
    }

    if (!globalSettings) {
      dispatch(emitFetchGlobalSettings());
    }

    if (location?.hash) {
      const section = document.querySelector(location.hash);
      if (section) {
        setTimeout(() => {
          section.scrollIntoView(true);
        }, 500);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleSettingsUpdate = async settings => {
    const updatedSettings = await SETTINGS_API.updateGlobalSettings(globalSettings._id, settings);
    const normalizedSettings = normalizeGlobalSettings(updatedSettings);

    dispatch(emitUpdateGlobalSettings(normalizedSettings));
  };

  const renderAddNew = path => {
    return (
      <Row justify="end">
        <Col>
          <Link to={path}>
            <LinkText>{t('Add new')}</LinkText>
          </Link>
        </Col>
      </Row>
    );
  };

  return (
    <PageContainerWithFadedLeftNavigation
      fullWidthPage
      navRoutes={[
        {
          to: `${INTERNAL_LINKS.GLOBAL_SETTINGS}#countriesSupported`,
          label: t('countries'),
        },
        {
          to: `${INTERNAL_LINKS.GLOBAL_SETTINGS}#cronJobs`,
          label: t('CRON Jobs'),
        },
        {
          to: `${INTERNAL_LINKS.GLOBAL_SETTINGS}#domains`,
          label: t('domains'),
        },
        {
          to: `${INTERNAL_LINKS.GLOBAL_SETTINGS}#freeTrialSetting`,
          label: t('freeTrialSetting'),
        },
        {
          to: `${INTERNAL_LINKS.GLOBAL_SETTINGS}#metabaseReports`,
          label: t('metabaseReports'),
        },
        {
          to: `${INTERNAL_LINKS.GLOBAL_SETTINGS}#passwordSettings`,
          label: t('passwordSettings'),
        },
        {
          to: `${INTERNAL_LINKS.GLOBAL_SETTINGS}#payrollFile`,
          label: t('payrollFile'),
        },
        {
          to: `${INTERNAL_LINKS.GLOBAL_SETTINGS}#timezones`,
          label: t('timezones'),
        },
        {
          to: `#trips`,
          label: t('trips'),
        },
      ]}
    >
      <Helmet>
        <title>{formatPageTitle('Global Settings')}</title>
      </Helmet>

      <Row id="countriesSupported">
        <Col flex={1}>
          <Box>
            <Text variant="h5">{t('countriesSupported')}</Text>
            <br />
            <CountriesTable
              t={t}
              loading={!countryCodeMappings.length}
              dataSource={countryCodeMappings}
              onEdit={id => history.push(`${INTERNAL_LINKS.UPDATE_COUNTRY}/${id}`)}
            />
            <br />
            {renderAddNew(INTERNAL_LINKS.CREATE_COUNTRY)}
          </Box>
        </Col>
      </Row>

      <br />

      <DomainsSection
        t={t}
        allowedDomains={globalSettings?.allowedDomains}
        blockedDomains={globalSettings?.blockedDomains}
        onSettingsSave={handleSettingsUpdate}
      />

      <br />

      <FreeTrialSettingsSection
        t={t}
        globalSettings={globalSettings}
        onSettingsSave={handleSettingsUpdate}
      />

      <br />

      <MetabaseReportsSection t={t} globalSettings={globalSettings} history={history} />

      <br />

      <PasswordSettingSection
        t={t}
        globalSettings={globalSettings}
        onSettingsSave={handleSettingsUpdate}
      />

      <br />

      <GlobalPayrollFilSettingsSection t={t} />

      <br />

      <TimezonesSection
        t={t}
        timezones={globalSettings?.timezones}
        onSettingsSave={handleSettingsUpdate}
      />

      <br />

      <TripsSection
        t={t}
        businessPurposeList={globalSettings?.businessPurposeList}
        mileageValues={globalSettings?.mileageValues}
        onSettingsSave={handleSettingsUpdate}
      />

      <br />

      <Row>
        <Col flex={1}>
          <Box>
            <Text variant="h5">{t('defaultMapViewProvider')}</Text>
            <br />
            <DefaultMappingSettingForm t={t} />
          </Box>
        </Col>
      </Row>

      <br />

      <Row>
        <Col flex={1}>
          <Box>
            <Text variant="h5">{t('defaultMappingSetting')}</Text>
            <br />
            <DefaultMapViewProviderForm t={t} />
          </Box>
        </Col>
      </Row>

      <br />

      <Row id="cronJobs">
        <Col flex={1}>
          <Box>
            <Text variant="h5">
              CRON Jobs{' '}
              <LinkText
                renderAs="a"
                target="_blank"
                rel="noopener noreferrer"
                href={process.env.REACT_APP_AGENDA_DASH_URL}
              >
                {t('view')}
              </LinkText>
            </Text>
            <FadedText>{t('requiresAdditionalCredentials')}</FadedText>
          </Box>
        </Col>
      </Row>
    </PageContainerWithFadedLeftNavigation>
  );
};

export default withNamespaces()(withAuthentication(GlobalSettingsView));
