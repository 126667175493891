import { Col, Row } from 'antd';
import { get, map, merge } from 'lodash';
import React, { useCallback, useMemo } from 'react';

import PayrollFileConfigurationAPI from '../../../api/payroll-file-configurations';
import { CustomTable } from '../../../components';
import Box from '../../../components/Box';
import Text from '../../../components/Text';
import usePaginatedFiltersQuery from '../../../hooks/queries/usePaginatedFiltersQuery';
import useTableExpandedRows from '../../../hooks/useTableExpandedRows';
import PayrollExpandedRow from '../../company-details/PayrollFileConfigurationSection/PayrollExpandedRow';

const GlobalPayrollFilSettingsSection = ({ t }) => {
  const { expandedRowKeys, toggleExpandedRow } = useTableExpandedRows();

  const setExpandableOnDocuments = useCallback(data => {
    return merge({}, data, {
      documents: map(get(data, 'documents'), document => merge({}, document, { expandable: true })),
    });
  }, []);

  const COLUMNS = useMemo(
    () => [
      {
        fixed: 'left',
        dataIndex: 'name',
        title: t('fileName'),
      },
    ],
    [t],
  );

  const {
    query: payrollFileConfigurationQuery,
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
  } = usePaginatedFiltersQuery({
    select: setExpandableOnDocuments,
    queryKey: ['fetchPayrollFileConfiguration', 'isGlobal'],
    queryFn: () =>
      new PayrollFileConfigurationAPI().fetchPayrollFileConfiguration(
        { isGlobal: true },
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
  });

  return (
    <Row id="payrollFile">
      <Col flex={1}>
        <Box>
          <Text variant="h5">{t('payrollFile')}</Text>
          <br />
          <CustomTable
            withExpandableRows
            showSearchInput={false}
            expandedRowKeys={expandedRowKeys}
            loading={payrollFileConfigurationQuery.isFetching}
            onExpand={(expanded, record) => toggleExpandedRow(record)}
            columns={COLUMNS}
            dataSource={get(payrollFileConfigurationQuery.data, 'documents', [])}
            pagination={{
              pageSize: paginationConfig.pageSize,
              total: paginationConfig.total,
              current: paginationConfig.current,
              onShowSizeChange: handlePageSizeChange,
            }}
            onChange={({ current }, filters, sorters) => {
              handlePageChange(current);
            }}
            expandedRowRender={rowData => <PayrollExpandedRow t={t} {...rowData} />}
            expandedRowStyle={{ padding: 20, background: '#eeeff19e' }}
            scroll={{ x: true }}
          />
        </Box>
      </Col>
    </Row>
  );
};

export default GlobalPayrollFilSettingsSection;
