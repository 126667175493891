import { find } from 'lodash';
import React from 'react';

import usePaymentSchedules from '../../hooks/usePaymentSchedules';
import Select from '../Select';

const PaymentScheduleSelect = props => {
  const { disabled, onChange, ...rest } = props;

  const {
    isFetchingPaymentSchedules,
    paymentScheduleOptions,
    paymentSchedules,
  } = usePaymentSchedules();

  return (
    <Select
      {...rest}
      fullWidth
      showSearch
      allowClear={false}
      disabled={disabled || isFetchingPaymentSchedules}
      loading={isFetchingPaymentSchedules}
      options={paymentScheduleOptions}
      optionFilterProp="children"
      onChange={(value, option) => {
        if (typeof onChange === 'function') {
          onChange(value, option, find(paymentSchedules, { _id: value }));
        }
      }}
    />
  );
};

export default PaymentScheduleSelect;
