import { Col, Row } from 'antd';
import { filter, find, get, isString, merge } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DotDotDot from 'react-dotdotdot';

import { IMAGES, STATUS_LIST } from '../../../enum';
import useDebouncedState from '../../../hooks/useDebouncedState';
import useDidUpdateEffect from '../../../hooks/useDidUpdateEffect';
import { checkIfStringContainsValue, getUserFullName, momentTimezone } from '../../../utils/common';
import { formatNumberWithCurrency } from '../../../utils/numbers';
import {
  renderBooleanCheckmarks,
  sortColumnByBoolean,
  sortColumnByMomentDate,
  sortColumnByNumber,
  sortColumnByStatus,
  sortColumnByStringField,
} from '../../../utils/tables';
import {
  getTripCompanyLocationFromAddressLabel,
  getTripCompanyLocationToAddressLabel,
  hasTripsWithDeductedCommuteMiles,
} from '../../../utils/trips';
import TripPurposeChip from '../../Chip/TripPurposeChip';
import ErrorBoundary from '../../ErrorBoundary';
import { Button, CustomTable } from '../../index';
import ProfileNamePicture from '../../shared-ui/ProfileNamePicture';
import StatusTag from '../../Tag/StatusTag';
import Text from '../../Text';
import FadedText from '../../Text/FadedText';
import LinkText from '../../Text/LinkText';
import Tooltip from '../../Tooltip';
import TripTypeSelect from '../../TripTypeSelect';
import ExpandedTripsSubRow from './ExpandedTripsSubRow';
import JourneyDistanceDisplay from './JourneyDistanceDisplay';
import classNames from './style.module.scss';

/**
 * Table with Trips data
 */
const TripsTable = props => {
  const {
    t,
    authUserID,
    onDeleteTrip,
    displayAwaitingStatusHelp,
    tripsToRemove,
    // onRemoveTrip,
    // onUndoTripRemove,
    // displayMoreInfoRemoveTrips,
    handleViewFullScreen,
    dataSource,
    canRejectTrip,
    isRejectingTrip,
    onTripReject,
    isRecalculatingAmount,
    recalculatingTripId,
    onAmountRecalculate,
    onTripPurposeChange,
    onViewMoreComments,
    asyncSort,
    onSearchTermChange,
    onFetchDetails,
    onExpandRow,
    expandedTrip,
    onUpdateAndSubmit,
    ...rest
  } = props;
  const [fetchedTrips, setFetchedTrips] = useState({});
  const [fetchingRowKeys, setFetchingRowKeys] = useState([]);
  const [expandedRowsKeys, setExpandedRowsKeys] = useState(expandedTrip ? [expandedTrip] : []);

  const [searchTerm, setSearchTerm] = useDebouncedState();

  const addFetchingRow = useCallback(id => setFetchingRowKeys(array => [...array, id]), []);
  const removeFetchingRow = useCallback(id => setFetchingRowKeys(array => filter(array, id)), []);
  const setColumnSorter = (columnKey, sorter = false) => {
    const disabledColumnsOnAsyncSort = ['to_loc', 'from_loc', 'time', 'reject'];

    if (asyncSort) return !disabledColumnsOnAsyncSort.includes(columnKey);
    return sorter;
  };

  useDidUpdateEffect(() => {
    if (expandedTrip) {
      const expandedTripAvailable = find(dataSource, { _id: expandedTrip });
      if (!expandedTripAvailable) {
        setExpandedRowsKeys([]);
        onExpandRow();
      }
    }
  }, [expandedTrip, dataSource]);

  const filteredDataSource = useMemo(() => {
    let array = [...dataSource];

    if (searchTerm) {
      array = array.filter(trip => {
        const groupName = Array.isArray(trip.group) ? trip.group[0].name : '-';

        return (
          // TODO: use label instead of type
          checkIfStringContainsValue(trip.tripType, searchTerm) ||
          checkIfStringContainsValue(getUserFullName(trip), searchTerm) ||
          checkIfStringContainsValue(trip.from_loc, searchTerm) ||
          checkIfStringContainsValue(trip.to_loc, searchTerm) ||
          checkIfStringContainsValue(trip.date, searchTerm) ||
          checkIfStringContainsValue(
            momentTimezone(trip.journeyStartTs).format('hh:mm'),
            searchTerm,
          ) ||
          checkIfStringContainsValue(
            momentTimezone(trip.journeyEndTs).format('hh:mm'),
            searchTerm,
          ) ||
          checkIfStringContainsValue(trip.journeyDistance, searchTerm) ||
          checkIfStringContainsValue(groupName, searchTerm) ||
          checkIfStringContainsValue(
            moment(trip.journeyEndTs).diff(moment(trip.journeyStartTs), 'minutes').toString(),
            searchTerm,
          ) ||
          checkIfStringContainsValue(
            formatNumberWithCurrency(trip.amount, trip.currency),
            searchTerm,
          ) ||
          checkIfStringContainsValue(
            STATUS_LIST().StatusTagColorConfig[trip.status.toLowerCase()].label,
            searchTerm,
          )
        );
      });
    }

    return array;
  }, [dataSource, searchTerm]);

  const COLUMNS = [
    ...(canRejectTrip
      ? [
          {
            key: 'reject',
            title: '',
            width: 90,
            render: (actions, data) => {
              return (
                <Button
                  size="sm"
                  variant="secondary"
                  disabled={isRejectingTrip}
                  onClick={e => {
                    e.stopPropagation();
                    onTripReject(data._id);
                  }}
                >
                  {t('reject')}
                </Button>
              );
            },
          },
        ]
      : []),
    {
      width: 195,
      key: 'tripType',
      title: t('purpose'),
      sorter: setColumnSorter('tripType', sortColumnByStringField('tripType').sorter),
      render: (actions, data) => (
        <Row wrap={false} align="middle">
          {(!!data.notes || !!data.latestComment || !!data.hasNotes) && (
            <Col flex="40px">
              <img width="24px" height="24px" src={IMAGES.NOTES_PAPER_TEXT} alt="notes" />
            </Col>
          )}
          <Col flex={1}>
            {typeof onTripPurposeChange === 'function' &&
            [STATUS_LIST().Status.PENDING, STATUS_LIST().Status.DENIED].includes(data.status) ? (
              <TripTypeSelect
                trip={data}
                disabled={rest?.loading}
                style={{ width: '100%' }}
                defaultValue="Business"
                value={data.tripType}
                onChange={purpose => onTripPurposeChange(data._id, purpose)}
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            ) : (
              <>
                {typeof data.tripType === 'string' ? (
                  data.tripType === 'business-commute' ? (
                    <Row gutter={[0, 3]}>
                      <Col xs={24}>
                        <TripPurposeChip purpose={'commute'} />
                      </Col>
                      <Col xs={24}>
                        <TripPurposeChip purpose={'business'} />
                      </Col>
                    </Row>
                  ) : (
                    <TripPurposeChip purpose={data.tripType} />
                  )
                ) : (
                  '-'
                )}
              </>
            )}
          </Col>
        </Row>
      ),
    },
    {
      width: 130,
      align: 'center',
      key: 'isCRMTrip',
      title: t('crmMatch'),
      sorter: setColumnSorter('isCRMTrip', sortColumnByBoolean('isCRMTrip').sorter),
      ...renderBooleanCheckmarks('isCRMTrip'),
    },
    {
      title: t('name'),
      key: ['user.firstName', 'user.lastName'].join(','),
      render: (actions, data) => {
        const userFullName = getUserFullName(data);

        if (!userFullName) return '-';

        return (
          <ProfileNamePicture
            t={t}
            linkableUser={data.driverID !== authUserID}
            user={{
              ...(!userFullName ? { firstName: '-', lastName: '' } : get(data, 'user', {})),
              _id: data.driverID,
            }}
          />
        );
      },
      sorter: setColumnSorter('name', (a, b) => {
        const joinedNameA = getUserFullName(a);
        const joinedNameB = getUserFullName(b);
        return joinedNameA.localeCompare(joinedNameB);
      }),
    },
    {
      title: t('Group'),
      key: 'group.name',
      dataIndex: ['group', 'name'],
      render: (groupName, data) => groupName || '-',
      sorter: setColumnSorter('groupName', (a, b) => {
        const groupNameA = Array.isArray(a.group)
          ? get(a[0], 'group.name')
          : get(a, 'group.name', '-');
        const groupNameB = Array.isArray(b.group)
          ? get(b[0], 'group.name')
          : get(b, 'group.name', '-');
        return groupNameA.localeCompare(groupNameB);
      }),
    },
    {
      width: 100,
      key: 'journeyStartTs',
      title: t('date'),
      defaultSortOrder: 'descend',
      sorter: setColumnSorter(
        'journeyStartTs',
        sortColumnByMomentDate('date', 'MM/DD/YYYY zz').sorter,
      ),
      render: (actions, data) => {
        return (
          <p style={{ minWidth: 100, maxWidth: 'max-content' }}>
            {data.captureMode === 'Daily-Mileage' ? data.dateNoTimezone : data.date}
          </p>
        );
      },
    },
    {
      width: 190,
      key: 'time',
      title: t('time'),
      sorter: setColumnSorter('time', sortColumnByMomentDate('starttime', 'hh.mm.ss A').sorter),
      render: (actions, data) => {
        if (data.captureMode === 'Daily-Mileage') {
          return '-';
        }

        const timeFrame = [
          momentTimezone(data.journeyStartTs).format('hh:mm a'),
          momentTimezone(data.journeyEndTs).format('hh:mm a'),
        ].join(' - ');

        let time = '';
        if (data.journeyStartTs && data.journeyEndTs) {
          time = `(${moment(data.journeyEndTs).diff(moment(data.journeyStartTs), 'minutes')}min)`;
        }

        return <p style={{ minWidth: 190, maxWidth: 'max-content' }}>{`${timeFrame} ${time}`}</p>;
      },
    },
    {
      width: 50,
      title: t('Distance'),
      align: 'right',
      key: 'journeyDistance',
      dataIndex: 'journeyDistance',
      sorter: setColumnSorter('journeyDistance', sortColumnByNumber('journeyDistance').sorter),
      render: (distance, trip) => (
        <JourneyDistanceDisplay
          t={t}
          distance={distance}
          originalJourneyDistance={get(trip, 'originalJourneyDistance')}
          additionalJourneyDistance={get(trip, 'additionalJourneyDistance')}
        />
      ),
    },
    {
      width: 200,
      title: t('from'),
      key: 'from_loc',
      sorter: setColumnSorter('from_loc', sortColumnByStringField('from_loc').sorter),
      render: (actions, data) => {
        const stringLoc = isString(data.from_loc) ? data.from_loc : '';

        if (data.isFromAddressHome) {
          return (
            <Tooltip title={stringLoc}>
              <LinkText>{t('home')}</LinkText>
            </Tooltip>
          );
        }

        const companyLocationLabel = getTripCompanyLocationFromAddressLabel(data);
        if (companyLocationLabel) {
          return (
            <Tooltip title={stringLoc}>
              <LinkText>{companyLocationLabel}</LinkText>
            </Tooltip>
          );
        }

        return (
          <div title={stringLoc}>
            <DotDotDot clamp={2}>{stringLoc || '-'}</DotDotDot>
          </div>
        );
      },
    },
    {
      width: 200,
      title: t('to'),
      key: 'to_loc',
      sorter: setColumnSorter('to_loc', sortColumnByStringField('to_loc').sorter),
      render: (actions, data) => {
        const stringLoc = isString(data.to_loc) ? data.to_loc : '';

        if (data.isToAddressHome) {
          return (
            <Tooltip title={stringLoc}>
              <LinkText>{t('home')}</LinkText>
            </Tooltip>
          );
        }

        const companyLocationLabel = getTripCompanyLocationToAddressLabel(data);
        if (companyLocationLabel) {
          return (
            <Tooltip title={stringLoc}>
              <LinkText>{companyLocationLabel}</LinkText>
            </Tooltip>
          );
        }

        return (
          <div title={stringLoc}>
            <DotDotDot clamp={2}>{stringLoc || '-'}</DotDotDot>
          </div>
        );
      },
    },
    {
      width: 100,
      title: t('Amount'),
      key: 'pricing.totalAmount',
      align: 'right',
      sorter: setColumnSorter('amount', sortColumnByNumber('amount').sorter),
      render: (actions, data) => {
        const amount = formatNumberWithCurrency(data.amount, data?.currency);

        const TOOLTIP_VISIBLE = isRecalculatingAmount && recalculatingTripId === data._id;
        const CALCULATING_DIFFERENT_TRIP =
          isRecalculatingAmount && recalculatingTripId !== data._id;

        if (typeof onAmountRecalculate === 'function' && parseFloat(data.amount) === 0) {
          return (
            <Tooltip
              {...(TOOLTIP_VISIBLE
                ? { visible: true }
                : CALCULATING_DIFFERENT_TRIP
                ? { visible: false }
                : {})}
              className={classNames.amountTooltip}
              title={
                <Row onClick={e => e.stopPropagation()}>
                  <Col>
                    <Row>
                      <Text variant="p" size="sm">
                        {t('kliksHasNotYetCalculated')}
                      </Text>
                      <Text variant="p" size="sm">
                        {t('amountsAreCalculatedNightly')}
                      </Text>
                    </Row>

                    <Row style={{ margin: '15px 0' }}>
                      <Text variant="p" size="sm">
                        {t('toCalculateAmountNow')}:
                      </Text>
                    </Row>

                    <Row justify="center">
                      <Button
                        size="xs"
                        text="calculateTripAmount"
                        loading={isRecalculatingAmount}
                        onClick={e => {
                          e.stopPropagation();
                          onAmountRecalculate(data._id);
                        }}
                      />
                    </Row>
                  </Col>
                </Row>
              }
            >
              <Text textAlign="right" variant="p" size="sm" color="primary" renderAs="span">
                {amount}
              </Text>
            </Tooltip>
          );
        }

        return amount;
      },
    },
    {
      width: 50,
      title: t('GPS'),
      key: 'gpsVerified',
      align: 'center',
      ...renderBooleanCheckmarks('gps'),
      sorter: setColumnSorter('gpsVerified', (a, b) => (a.gps !== b.gps ? 1 : -1)),
    },
    {
      width: 160,
      title: t('status'),
      key: 'journeyStatus',
      render: (actions, data) => {
        if (data.status) {
          return (
            <>
              <StatusTag status={data.status} />

              {displayAwaitingStatusHelp &&
                typeof t === 'function' &&
                data.status === STATUS_LIST().Status.SUBMITTED && (
                  <FadedText size="sm">{t('tripApprovedWhenReceiptApproved')}</FadedText>
                )}
            </>
          );
        } else {
          return '-';
        }
      },
      sorter: setColumnSorter('journeyStatus', sortColumnByStatus('status').sorter),
    },
  ];

  const toggleExpandedRow = row => {
    if (!expandedRowsKeys.includes(row.key)) {
      setExpandedRowsKeys([row.key]);
      if (typeof onExpandRow === 'function') onExpandRow(row.key);
    } else {
      setExpandedRowsKeys([]);
      if (typeof onExpandRow === 'function') onExpandRow();
    }
  };

  useEffect(() => {
    const expandedTripId = expandedRowsKeys[0];

    if (
      typeof onFetchDetails === 'function' &&
      !!expandedTripId &&
      !Object.keys(fetchedTrips).includes(expandedTripId) &&
      !fetchingRowKeys.includes(expandedTripId)
    ) {
      addFetchingRow(expandedTripId);
      onFetchDetails(expandedTripId).finally(() => {
        removeFetchingRow(expandedTripId);
        setFetchedTrips(obj => {
          return merge(obj, { [expandedTripId]: true });
        });
      });
    }
  }, [
    fetchedTrips,
    fetchingRowKeys,
    expandedRowsKeys,
    onFetchDetails,
    addFetchingRow,
    removeFetchingRow,
  ]);

  return (
    <CustomTable
      onSearchTermChange={onSearchTermChange || setSearchTerm}
      {...rest}
      dataSource={filteredDataSource}
      className="trips-table"
      rowClassName={data => {
        if (Array.isArray(tripsToRemove) && tripsToRemove.includes(data._id)) {
          return classNames.blurredRow;
        }
      }}
      withExpandableRows
      columns={COLUMNS}
      scroll={{ x: 1400 }}
      onRow={row => ({
        className: 'trips-table-row',
        leftBorderColor: hasTripsWithDeductedCommuteMiles([row]) ? 'danger' : '',
      })}
      expandedRowKeys={expandedRowsKeys}
      onExpand={(expanded, record) => toggleExpandedRow(record)}
      expandedRowRender={data => (
        <ErrorBoundary>
          <ExpandedTripsSubRow
            t={t}
            trip={data}
            authUserID={authUserID}
            isFetchigDetails={fetchingRowKeys.includes(data._id)}
            onDeleteTrip={onDeleteTrip}
            onViewMoreComments={onViewMoreComments}
            onViewFullScreenClick={handleViewFullScreen}
            onUpdateAndSubmit={onUpdateAndSubmit}
          />
        </ErrorBoundary>
      )}
    />
  );
};

export default TripsTable;
