import { formatQueryRange } from '../utils/common';
import { cleanQueryParams } from '../utils/queryParams';
import { handleApiCalls } from './axiosInstance';
import BaseAPI from './baseAPI';

class PayrollFileConfigurationAPI extends BaseAPI {
  constructor() {
    super({ suffix: 'payroll-file-configurations' });
  }

  /**
   * @param {Object} params - Parameters for fetching payroll file configuration.
   * @param {string} params.companyId - ID of the company.
   * @param {boolean} [params.isGlobal=false] - Flag indicating if the configuration is global.
   */
  async fetchPayrollFileConfiguration({ companyId, isGlobal = false }, pageNumber, pageSize) {
    const queryParams = cleanQueryParams({
      companyId,
      isGlobal,
      range: formatQueryRange(pageNumber, pageSize),
    });
    const result = await handleApiCalls('get', `${this.baseUrl}?${queryParams}`);

    return result.data;
  }

  async createGlobalPayrollFileConfiguration(params) {
    const result = await handleApiCalls('post', `${this.baseUrl}`, params);

    return result.data;
  }

  async updateGlobalPayrollFileConfiguration(fileId, params) {
    const result = await handleApiCalls('put', `${this.baseUrl}/${fileId}`, params);

    return result.data;
  }

  async fetchPayrollFileConfigurationDetails(fileId) {
    const result = await handleApiCalls('get', `${this.baseUrl}/${fileId}`);

    return result.data;
  }
}

export default PayrollFileConfigurationAPI;
