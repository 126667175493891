import { Col, Form, Row, Space } from 'antd';
import { get, isNull } from 'lodash';
import React, { useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { handleApiErrors } from '../../api/axiosInstance';
import { TRIP_API } from '../../api/trips';
import Button from '../Button';
import FormItem from '../Form/FormItem';
import SubmitCancelButtonGroup from '../SubmitCancelButtonGroup';
import NumericInput from '../TextInput/NumericInput';
import Toast from '../Toast';

const TripsAddAdditionalDistanceSection = props => {
  const { t, trip, onUpdateAndSubmit } = props;

  const queryClient = useQueryClient();
  const [formInstace] = Form.useForm();

  const updateTripDistanceMutation = useMutation(
    ({ additionalJourneyDistance }) => {
      const distance = parseFloat(additionalJourneyDistance);
      return TRIP_API.updateTrip(trip._id, {
        additionalJourneyDistance: isNaN(distance) ? 0 : distance,
      });
    },
    {
      onSuccess: async (response, { submit }) => {
        Toast({
          type: 'open',
          message: t('updateTripDistanceSuccess'),
        });

        if (submit) {
          onUpdateAndSubmit(trip._id);
        }

        queryClient.refetchQueries({
          inactive: false,
          exact: false,
          queryKey: ['companyTrips'],
        });
      },
      onError: error =>
        handleApiErrors(error.response, () => {
          Toast({
            type: 'open',
            message: t('updateTripDistanceError'),
          });
        }),
    },
  );

  const [initialDistanceValue, originalDistance, distanceUnit] = useMemo(() => {
    const journeyDistance = get(trip, 'journeyDistance', '');

    let additionalJourneyDistance = get(trip, 'additionalJourneyDistance', '');
    let originalJourneyDistance = get(trip, 'originalJourneyDistance', '');
    additionalJourneyDistance = !isNull(additionalJourneyDistance)
      ? parseFloat(additionalJourneyDistance)
      : '';
    originalJourneyDistance = !isNull(originalJourneyDistance)
      ? parseFloat(originalJourneyDistance)
      : parseFloat(journeyDistance);

    return [
      additionalJourneyDistance === 0 ? '' : additionalJourneyDistance,
      originalJourneyDistance,
      journeyDistance.split(' ')[1],
    ];
  }, [trip]);

  return (
    <section>
      <Form
        form={formInstace}
        disabled={updateTripDistanceMutation.isLoading}
        onFinish={updateTripDistanceMutation.mutateAsync}
        defaultValue={{ additionalJourneyDistance: initialDistanceValue }}
      >
        <Space direction="vertical" size="small">
          {initialDistanceValue > 0 && (
            <FormItem label={t('originalDistance')} className="no-margin-bottom">
              <NumericInput disabled suffix={distanceUnit} defaultValue={originalDistance} />
            </FormItem>
          )}

          <FormItem
            name="additionalJourneyDistance"
            label={t('addAdditionalDistance')}
            className="no-margin-bottom"
          >
            <NumericInput suffix={distanceUnit} defaultValue={initialDistanceValue} />
          </FormItem>

          <Row gutter={[8, 8]} align="middle" justify="end">
            <Col>
              <Button
                size="xs"
                onClick={() => {
                  formInstace.validateFields().then(values => {
                    updateTripDistanceMutation.mutateAsync({ ...values, submit: true });
                  });
                }}
              >
                {t('submitForPayment')}
              </Button>
            </Col>

            <Col>
              <SubmitCancelButtonGroup
                isForm
                hideCancel
                size="xs"
                submitText={t('updateDistance')}
              />
            </Col>
          </Row>
        </Space>
      </Form>
    </section>
  );
};

export default TripsAddAdditionalDistanceSection;
