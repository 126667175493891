import './style.scss';

import { Col, Row, Space } from 'antd';
import { find, get } from 'lodash';
import moment from 'moment-timezone';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Trans, withNamespaces } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  handleApiErrors,
  performApiCallIfCompanySubIsActiveOrCompanyAdmin,
} from '../../api/axiosInstance';
import { TRIP_API } from '../../api/trips';
import { USER_API } from '../../api/user';
import { ConfirmModal } from '../../components';
import Button from '../../components/Button';
import DownloadIcon from '../../components/DownloadIcon';
import CreateTripModal from '../../components/Modal/CreateTripModal';
import FullScreenTripModal from '../../components/Modal/FullScreenTripModal';
import TripsCommentsModal from '../../components/Modal/TripsCommentsModal';
import TripsReceiptConfirmationModal from '../../components/Modal/TripsReceiptConfirmationModal';
import CommonTableFilters from '../../components/shared-ui/CommonTableFilters';
import Switch from '../../components/Switch';
import TripsTable from '../../components/Table/TripsTable';
import Text from '../../components/Text';
import LinkText from '../../components/Text/LinkText';
import TextWithExternalLink from '../../components/TextWithExternalLink';
import Toast from '../../components/Toast';
import Tooltip from '../../components/Tooltip';
import PageContainer from '../../containers/PageContainer';
import { EXTERNAL_LINKS, INTERNAL_LINKS, STATUS_LIST, TRIP_CONF, USER_ROLES } from '../../enum';
import withAuthentication from '../../hocs/withAuthentication';
import useAuthUser from '../../hooks/useAuthUser';
import useDidUpdateEffect from '../../hooks/useDidUpdateEffect';
import useLocationSearchQueryParser from '../../hooks/useLocationSearchQueryParser';
import useModal from '../../hooks/useModal';
import useTablePagination from '../../hooks/useTablePagination';
import useTableSort from '../../hooks/useTableSort';
import useTripsComments from '../../hooks/useTripsComments';
import {
  formatPageTitle,
  getUserFullName,
  momentFormat,
  replaceValueInArrayByID,
} from '../../utils/common';
import { convertGroupListIntoValidSelectOptions } from '../../utils/groups';
import { getMinutesAsMilliseconds } from '../../utils/numbers';
import { replaceCurrentPageSearchQueryParams } from '../../utils/queryParams';
import { hasUserRole } from '../../utils/roles';
import { StorageUtils } from '../../utils/sessionStorage';
import { selectStoreCompanyGroups, selectStoreCompanySettings } from '../../utils/storeSelectors';
import { normalizeTripsSchema } from '../../utils/trips';
import {
  canAddCommentOnTrip,
  canCreateTripManually,
  canDeleteTrip,
  canSubmitForApproval,
  canUploadTripViaCSV,
} from './trips-permissions';
import { validateManualTrip } from './trips-validation';

/**
 * Trips page
 */
const TripsPage = props => {
  const { t, history } = props;

  const queryClient = useQueryClient();
  const { authUser, authUserGroup } = useAuthUser();

  const tripIdToSubmitOnRefetch = useRef();

  const companyGroups = useSelector(selectStoreCompanyGroups);

  const {
    status,
    user,
    group,
    searchTerm,
    startDateRange,
    endDateRange,
    crmMatch,
    page,
    pageSize,
    expandedTrip,
    refUser,
    ...initialQueryParams
  } = useLocationSearchQueryParser(props.location);

  const companySettings = useSelector(selectStoreCompanySettings);

  const [showAllPending, setShowAllPending] = useState(
    hasUserRole(authUser) ? get(initialQueryParams, 'showAllPending', false) : false,
  );
  const handleShowAllPendingChange = useCallback(
    active => {
      if (!active) setStatusFilter(null);
      setShowAllPending(active);
      replaceCurrentPageSearchQueryParams(history, { showAllPending: active });
    },
    [history],
  );

  const dashboardDataQuery = useQuery({
    placeholderData: {},
    staleTime: getMinutesAsMilliseconds(5),
    queryKey: ['fetchDashboardData', authUser?.profile?._id, authUser?.profile?.group?._id, '', ''],
    enabled: hasUserRole(authUser),
    queryFn: () =>
      USER_API.fetchDashboardData({
        groupId: authUser?.profile?.group?._id,
      }),
  });

  const {
    paginationConfig,
    handlePageChange,
    handlePageSizeChange,
    handleTotalUpdate,
  } = useTablePagination({ initialPage: page, initialPageSize: pageSize, useQueryParams: true });

  const [isDeleteTripModalOpen, openDeleteTripModal, closeDeleteTripModal] = useModal();
  const [isCreateTripModalVisible, openCreateTripModal, closeCreateTripModal] = useModal();

  const [termFilter] = useState(searchTerm || '');

  const [statusFilter, setStatusFilter] = useState(
    status === '' ? null : status || STATUS_LIST().Status.PENDING,
  );
  const [crmMatchFilter, setCrmMatchFilter] = useState(
    typeof crmMatch === 'boolean' ? crmMatch : null,
  );
  const [dateRange, setDateRange] = useState(() => [
    startDateRange ? moment(startDateRange) : moment().subtract(1, 'month'),
    endDateRange ? moment(endDateRange) : moment(),
  ]);

  const [isSubmittingTrips, setIsSubmittingTrips] = useState(false);
  const [isSubmitTripsModalVisible, setIsSubmitTripsModalVisible] = useState(false);

  const [isTripsDetailModalVisible, setIsTripsDetailModalVisible] = useState(false);
  const [selectedMapConfig, setSelectedMapConfig] = useState({
    routes: [],
    center: [0, 0],
  });

  const [selectedDriverID, setSelectedDriverID] = useState(user || null);
  const [selectedGroupID, setSelectedGroupID] = useState(group || null);

  const [selectedTrips, setSelectedTrips] = useState([]);
  const [selectedDeletableTrips, setSelectedDeletableTrips] = useState([]);
  const [isOtherDriverTripSelected, setIsOtherDriverTripSelected] = useState(false);

  const [isUpdatingTrip, setIsUpdatingTrip] = useState(false);
  const [trips, setTrips] = useState([]);

  const { stringTableSort, handleTableSort } = useTableSort({ journeyStartTs: -1 });

  const [recalculatingTripProp, setRecalculatingTripProp] = useState({
    isRecalculatingAmount: false,
    recalculatingTripId: undefined,
  });

  const resetSelectedTrips = () => {
    setSelectedTrips([]);
    setSelectedDeletableTrips([]);
  };

  const updateTripLatestComment = useCallback(
    (tripId, commentData) => {
      const updatedTrips = replaceValueInArrayByID(trips, {
        _id: tripId,
        latestComment: commentData,
      });
      setTrips(updatedTrips);
    },
    [trips],
  );

  const {
    tripCommentsQuery,
    commentsPaginationConfig,
    tripIdToViewComments,
    isAddingComment,
    isTripCommentsModalVisible,
    openTripCommentsModal,
    closeTripCommentsModal,
    addCommentToTrip,
    handleCommentsPageChange,
  } = useTripsComments({ onAddTripComment: updateTripLatestComment });

  useEffect(() => {
    const loggedInUserPendingTrips = selectedTrips.filter(
      trip => trip.driverID === authUser.profile._id,
    );

    setIsOtherDriverTripSelected(loggedInUserPendingTrips.length !== selectedTrips.length);
  }, [selectedTrips, authUser]);

  // Reset current page index whenever the filters change
  useDidUpdateEffect(() => {
    handlePageChange(1);
  }, [statusFilter, selectedDriverID, termFilter, dateRange, crmMatchFilter, handlePageChange]);

  const [fromDateStr, toDateStr] = useMemo(() => {
    if (showAllPending && dashboardDataQuery.data) {
      return [
        momentFormat(dashboardDataQuery.data?.oldestJourneyStartTs, 'YYYY-MM-DD'),
        momentFormat(new Date(), 'YYYY-MM-DD'),
      ];
    }
    return [
      dateRange[0] ? momentFormat(dateRange[0], 'YYYY-MM-DD') : undefined,
      dateRange[1] ? momentFormat(dateRange[1], 'YYYY-MM-DD') : undefined,
    ];
  }, [showAllPending, dashboardDataQuery.data, dateRange]);

  const tripListQueryKey = useMemo(
    () => [
      'companyTrips',
      fromDateStr,
      toDateStr,
      showAllPending ? STATUS_LIST().Status.PENDING : statusFilter,
      selectedDriverID,
      selectedGroupID,
      termFilter,
      crmMatchFilter,
      paginationConfig.current,
      paginationConfig.pageSize,
      stringTableSort,
      showAllPending,
      dashboardDataQuery.data,
    ],
    [
      fromDateStr,
      toDateStr,
      statusFilter,
      selectedDriverID,
      selectedGroupID,
      termFilter,
      crmMatchFilter,
      paginationConfig,
      stringTableSort,
      showAllPending,
      dashboardDataQuery.data,
    ],
  );

  const tripListQuery = useQuery({
    enabled: showAllPending ? !dashboardDataQuery.isFetching && !!dashboardDataQuery.data : true,
    queryFn: () =>
      TRIP_API.fetchTrips(
        {
          fromDate: fromDateStr,
          toDate: toDateStr,
          status: showAllPending ? STATUS_LIST().Status.PENDING : statusFilter,
          userId: selectedDriverID,
          groupId: selectedGroupID,
          searchTerm: termFilter,
          isCRMTrip: crmMatchFilter,
          sort: stringTableSort,
        },
        paginationConfig.current,
        paginationConfig.pageSize,
      ),
    queryKey: tripListQueryKey,
    onSuccess: response => {
      const tripsArr = get(response, 'data', []).map(trp => normalizeTripsSchema(trp, undefined));
      setTrips(tripsArr);
      handleTotalUpdate(response.totalCount);

      if (expandedTrip && Array.isArray(tripsArr) && !!tripsArr.length) {
        const tripRow = document.getElementById(expandedTrip);
        if (tripRow) {
          const container = document.querySelector('.main-app-container');
          const y = tripRow.offsetTop + container.offsetTop;
          container.scrollTo({ behavior: 'smooth', top: y });
        }
      }

      if (tripIdToSubmitOnRefetch.current) {
        setSelectedTrips([find(tripsArr, { _id: tripIdToSubmitOnRefetch.current })]);
        handleSubmitForApproval();

        tripIdToSubmitOnRefetch.current = null;
      }
    },
    onError: error => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('fetchTripsError'),
        });
      });
    },
  });

  const tripsListMutation = useMutation(TRIP_API.fetchTripDetails, {
    onSuccess: async data => {
      const queryData = queryClient.getQueryData(tripListQueryKey);
      const details = {
        _id: data._id,
        journeyGeoJson: data.journeyGeoJson,
        pricing: data.pricing,
        gpsVerified: data.gpsVerified,
        isCRMTrip: data.isCRMTrip,
        journeyStatus: data.journeyStatus,
        notes: data.notes,
        latestComment: data.latestComment,
      };
      if (queryData?.data) {
        replaceValueInArrayByID(queryData.data, details, '_id');
        queryClient.setQueryData(tripListQueryKey, queryData);
      }
      queryClient.setQueryData(['fetchTripDetails', data._id], details);
    },
    onError: error => handleApiErrors(error.response),
  });

  const tripsExportMutation = useMutation(
    () =>
      TRIP_API.exporTrips({
        fromDate: fromDateStr,
        toDate: toDateStr,
        status: showAllPending ? STATUS_LIST().Status.PENDING : statusFilter,
        userId: selectedDriverID,
        groupId: selectedGroupID,
        searchTerm: termFilter,
        isCRMTrip: crmMatchFilter,
        sort: stringTableSort,
      }),
    {
      onSuccess: () => {
        Toast({
          type: 'open',
          duration: 10, // seconds
          message: (
            <Trans
              t={t}
              i18nKey="exportBeingProcessed"
              components={[
                <LinkText variant="b" onClick={() => history.push(INTERNAL_LINKS.EXPORTS)}>
                  Dummy
                </LinkText>,
              ]}
            />
          ),
        });
      },
      onError: error => handleApiErrors(error.response),
    },
  );

  const _updateOneTripStateByID = (id, data = {}) => {
    const updatedTrips = [...trips];
    const TRIP_INDEX = trips.findIndex(trip => trip._id === id);
    const TRP_TO_UPDATE = updatedTrips[TRIP_INDEX];

    updatedTrips[TRIP_INDEX] = normalizeTripsSchema({
      ...TRP_TO_UPDATE,
      pricing: data.pricing,
    });

    setTrips(updatedTrips);
  };

  /**
   *
   */
  const handleTripPurposeChange = async (tripID, purpose) => {
    setTimeout(async () => {
      setIsUpdatingTrip(true);

      try {
        await TRIP_API.updateTrip(tripID, { tripType: purpose });
        tripListQuery.refetch();
      } catch (error) {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('tripPurposeUpdateError'),
          });
        });
      }

      setIsUpdatingTrip(false);
    }, 220);
  };

  /**
   * Performs an API call to submit the selected Trips for approval
   */
  const submitTripsForApproval = async submittedTripsData => {
    setIsSubmittingTrips(true);

    const url = `${process.env.REACT_APP_HOST_API}trip-receipt`;
    try {
      const result = await performApiCallIfCompanySubIsActiveOrCompanyAdmin(
        'post',
        url,
        { ids: submittedTripsData.ids },
        null,
        undefined,
        [USER_ROLES.COMPANY_ADMIN],
      );

      if (result && result.status === 200) {
        Toast({
          type: 'success',
          message: get(result, 'data.message') || t('tripsSubmitSuccess'),
        });
        resetSelectedTrips();
        setIsSubmitTripsModalVisible(false);
        tripListQuery.refetch();
      }
    } catch (error) {
      handleApiErrors(error.response, () => {
        Toast({ type: 'error', message: t('tripsSubmitError') });
      });
    }

    setIsSubmittingTrips(false);
  };

  const handleRecalculateTripAmount = async tripId => {
    setRecalculatingTripProp({
      isRecalculatingAmount: true,
      recalculatingTripId: tripId,
    });

    let HAS_ERRORS = false;
    let recalculatedTrip;

    try {
      recalculatedTrip = await TRIP_API.recalculateTripAmount(tripId);
    } catch (error) {
      handleApiErrors(error.response, () => {
        // Mark as error if error response is not handled
        HAS_ERRORS = true;
      });
    }

    if (!recalculatedTrip && HAS_ERRORS) {
      Toast({
        type: 'error',
        message: t('amountCalculatedOvernight'),
      });
    } else if (recalculatedTrip) {
      try {
        _updateOneTripStateByID(tripId, recalculatedTrip);
      } catch (error) {
        HAS_ERRORS = true;
        Toast({
          type: 'error',
          message: t('tableUpdateError'),
        });
      }

      if (!HAS_ERRORS && recalculatedTrip) {
        Toast({
          type: 'success',
          message: t('tripAmountCalculateSuccess'),
        });
      }
    }

    setRecalculatingTripProp({
      isRecalculatingAmount: false,
      recalculatingTripId: undefined,
    });
  };

  const handleSubmitForApproval = () => {
    const allowedTripStatus = [
      STATUS_LIST().Status.PENDING,
      STATUS_LIST().Status.OVERDUE,
      STATUS_LIST().Status.DENIED,
      STATUS_LIST().Status.MORE_INFO,
    ];

    const tripsWithInvalidStatus = selectedTrips.filter(
      trip => !allowedTripStatus.includes(trip.status),
    );

    if (!!tripsWithInvalidStatus.length) {
      Toast({
        duration: null,
        type: 'error',
        message: t('canOnlySubmitTripsWithThisStatus'),
      });
      return;
    }

    const loggedInUserPendingTrips = selectedTrips.filter(
      trip => trip.driverID === authUser.profile._id,
    );

    if (loggedInUserPendingTrips.length === selectedTrips.length) {
      setIsSubmitTripsModalVisible(true);
    } else if (isOtherDriverTripSelected) {
      Toast({
        type: 'error',
        message: t('youCannotSubmitOtherUserTrips'),
      });
    } else {
      Toast({
        type: 'error',
        message: t('tripsSubmitError'),
      });
    }
  };

  /**
   * Handler for manual Trip creation
   */
  const createTripMutation = useMutation(
    async data => {
      if (validateManualTrip(data)) {
        return TRIP_API.createManualTrip({
          tripType: data.purpose,
          startLocation: data.fullStartAddress,
          endLocation: data.fullEndAddress,
          notes: data.tripNotes,
          journeyStartTs: moment(
            `${data.startDate.format('MM/DD/YYYY')} ${data.startTime.format('HH:mm:ss')}`,
            'MM/DD/YYYY HH:mm:ss',
          ),
          journeyEndTs: data.isKliksCalculatingEndTime
            ? undefined
            : moment(
                `${data.endDate.format('MM/DD/YYYY')} ${data.endTime.format('HH:mm:ss')}`,
                'MM/DD/YYYY HH:mm:ss',
              ),
        });
      }
    },
    {
      onSuccess: accepted => {
        if (accepted) {
          closeCreateTripModal();
          tripListQuery.refetch();
        }
      },
      onError: error => {
        handleApiErrors(error.response, () => {
          Toast({
            type: 'error',
            message: t('processTripError'),
          });
        });
      },
    },
  );

  /**
   * Handler for Trip deletion
   */
  const handleTripDeletion = useMutation(tripIds => TRIP_API.deleteTrip(tripIds), {
    onSuccess: async (_, trips) => {
      await tripListQuery.refetch();
      closeDeleteTripModal();
      resetSelectedTrips();
      Toast({
        type: 'open',
        message: t('selectedTripsDeleted', { count: trips.length }),
      });
    },
    onError: (error, trips) => {
      handleApiErrors(error.response, () => {
        Toast({
          type: 'error',
          message: t('errorDeletingTrip', { count: trips.length }),
        });
      });
    },
  });

  const deleteSelectedTrips = useCallback(async () => {
    if (!!selectedDeletableTrips.length) {
      await handleTripDeletion.mutateAsync(selectedDeletableTrips.map(trip => trip._id));
    }
  }, [selectedDeletableTrips, handleTripDeletion]);

  const filteredCompanyGroups = useMemo(() => {
    if (Array.isArray(companyGroups)) {
      return convertGroupListIntoValidSelectOptions(companyGroups, authUser);
    }
    return [];
    // eslint-disable-next-line
  }, [companyGroups]);

  const handleUserChange = useCallback(
    (id, option) => {
      setSelectedDriverID(id);

      setTimeout(() => {
        if (id && option) {
          replaceCurrentPageSearchQueryParams(history, {
            refUser: JSON.stringify({
              label: getUserFullName(option),
              value: option._id,
            }),
          });
        } else {
          replaceCurrentPageSearchQueryParams(history, {
            refUser: null,
          });
        }
      }, 100);
    },
    [history],
  );

  useEffect(() => {
    if (hasUserRole(authUser)) {
      handleUserChange(authUser.profile._id, authUser.profile);
    }
  }, [authUser, handleUserChange]);

  useEffect(() => {
    if (!hasUserRole(authUser) && refUser) {
      StorageUtils.lastUserLookupSelection.set(JSON.parse(refUser));
    }
  }, [authUser, refUser]);

  return (
    <PageContainer
      stickyHeader
      title={t('Trips')}
      sideActionComponent={
        authUser && (
          <Row gutter={17}>
            <Col>
              <Button
                size="sm"
                type="primary"
                onClick={openDeleteTripModal}
                disabled={
                  !selectedDeletableTrips.length ||
                  handleTripDeletion.isLoading ||
                  tripListQuery.isFetching ||
                  isUpdatingTrip
                }
              >
                {t('deleteTrip', { count: selectedTrips.length })}
              </Button>
            </Col>

            {canUploadTripViaCSV(authUser) && (
              <Col>
                <Link to={INTERNAL_LINKS.MANAGE_UPLOADED_TRIPS}>
                  <Button
                    disabled={tripListQuery.isFetching || isUpdatingTrip}
                    type="primary"
                    size="sm"
                  >
                    {t('uploadCSV')}
                  </Button>
                </Link>
              </Col>
            )}

            {canCreateTripManually(authUser) &&
              authUserGroup?.mileageEntryMode !== 'daily-mileage-log' && (
                <Col>
                  <Button
                    disabled={tripListQuery.isFetching || isUpdatingTrip}
                    type="primary"
                    size="sm"
                    onClick={openCreateTripModal}
                  >
                    {t('addManualTrip')}
                  </Button>
                </Col>
              )}

            {authUserGroup?.mileageEntryMode === 'daily-mileage-log' && (
              <Col>
                <Link to={INTERNAL_LINKS.DAILY_MILEAGE_LOG}>
                  <Button type="primary" size="sm">
                    {t('addEditDailyMileage')}
                  </Button>
                </Link>
              </Col>
            )}

            {canSubmitForApproval(authUser) && (
              <Col>
                <Tooltip
                  enabled={isOtherDriverTripSelected}
                  title={t('youCannotSubmitOtherUserTrips')}
                >
                  <Button
                    disabled={!selectedTrips.length || isOtherDriverTripSelected}
                    type="primary"
                    size="sm"
                    onClick={handleSubmitForApproval}
                  >
                    {t('Submit Trips for Approval')}
                  </Button>
                </Tooltip>
              </Col>
            )}
          </Row>
        )
      }
    >
      <Helmet>
        <title>{formatPageTitle('Trips')}</title>
      </Helmet>

      <Space direction="vertical" size="small">
        <Row gutter={16} align="middle" justify="space-between" wrap={false}>
          <Col flex={1}>
            {tripListQuery.isFetched && (
              <CommonTableFilters
                hiddenFilters={
                  showAllPending ? ['search', 'type', 'dateRange', 'status'] : ['search', 'type']
                }
                loggedInUserProfile={authUser}
                userValue={selectedDriverID}
                onUserChange={handleUserChange}
                dateRangeValue={dateRange}
                onDateRangeChange={range => setDateRange(range)}
                groupValue={selectedGroupID}
                selectedGroupId={selectedGroupID}
                onGroupChange={id => setSelectedGroupID(id)}
                groupOptions={filteredCompanyGroups}
                statusHelp={
                  <TextWithExternalLink
                    text={t('seeOurFAQsArticleForTripStatus')}
                    linkProps={{ href: EXTERNAL_LINKS.FRESHDESK_FAQS_TRIP_STATUS }}
                  />
                }
                statusValue={statusFilter}
                statusOptions={
                  companySettings.ach_enabled
                    ? TRIP_CONF().SelectionOptionsWithACH
                    : TRIP_CONF().SelectOptions
                }
                onStatusChange={status => setStatusFilter(status)}
                crmMatchValue={crmMatchFilter}
                onCrmMatchChange={crmMatch => setCrmMatchFilter(crmMatch)}
                // searchTermValue={termFilter}
                // onSearchTermChange={newTerm => setTermFilter(newTerm)}
                disabledFilters={tripListQuery.isFetching || isUpdatingTrip}
              />
            )}
          </Col>

          <DownloadIcon
            t={t}
            colWidth="65px"
            text={t('exportToCSV')}
            loading={tripsExportMutation.isLoading || tripListQuery.isFetching || isUpdatingTrip}
            onClick={tripsExportMutation.mutateAsync}
            disabled={!paginationConfig.total || paginationConfig.total === 0}
          />
        </Row>

        {hasUserRole(authUser) && (
          <Row justify="end" align="middle" gutter={[8, 8]} wrap={false}>
            <Col>
              <Text size="sm">{t('showAllPending')}</Text>
            </Col>
            <Col>
              <Switch
                size="medium"
                defaultChecked={showAllPending}
                onChange={handleShowAllPendingChange}
              />
            </Col>
          </Row>
        )}

        <Row>
          <TripsTable
            t={t}
            onUpdateAndSubmit={tripId => {
              tripIdToSubmitOnRefetch.current = tripId;
            }}
            authUserID={authUser.profile._id}
            onTripPurposeChange={handleTripPurposeChange}
            onDeleteTrip={handleTripDeletion.mutateAsync}
            hiddenColumns={
              authUserGroup?.mileageEntryMode === 'daily-mileage-log'
                ? ['time', 'from_loc', 'to_loc', 'gpsVerified']
                : []
            }
            // Recalculate amount props
            onAmountRecalculate={handleRecalculateTripAmount}
            recalculatingTripId={recalculatingTripProp.recalculatingTripId}
            isRecalculatingAmount={recalculatingTripProp.isRecalculatingAmount}
            // ========
            showSearchInput={false}
            expandedTrip={expandedTrip}
            onExpandRow={expTrip => {
              replaceCurrentPageSearchQueryParams(history, { expandedTrip: expTrip });
            }}
            rowSelection={{
              selectedRowKeys: selectedTrips.map(trip => trip.key),
              onChange: (markedTripIDs, markedTripRecords) => {
                const markedTrips = markedTripRecords.filter(Boolean);
                setSelectedTrips(markedTrips);
                setSelectedDeletableTrips(
                  markedTrips.filter(
                    trip =>
                      canDeleteTrip(trip) &&
                      [
                        STATUS_LIST().Status.PENDING,
                        STATUS_LIST().Status.MORE_INFO,
                        STATUS_LIST().Status.DENIED,
                      ].includes(trip.status),
                  ),
                );
              },
            }}
            loading={tripListQuery.isFetching || isUpdatingTrip}
            pagination={{
              pageSize: paginationConfig.pageSize,
              total: paginationConfig.total,
              current: paginationConfig.current,
              onShowSizeChange: handlePageSizeChange,
            }}
            dataSource={trips}
            asyncSort
            onChange={({ current }, filters, sorters) => {
              handlePageChange(current);
              resetSelectedTrips();
              handleTableSort(sorters?.columnKey, sorters?.order);
            }}
            onFetchDetails={tripsListMutation.mutateAsync}
            onViewMoreComments={openTripCommentsModal}
            handleViewFullScreen={(trp, activeRoutes, mapCenter) => {
              setIsTripsDetailModalVisible(true);
              setSelectedMapConfig({
                tripId: trp._id,
                isManual: !trp.gpsVerified || trp.gpsVerified === 'No',
                toLoc: trp.to_loc,
                fromLoc: trp.from_loc,
                routes: activeRoutes,
                center: mapCenter,
              });
            }}
          />
        </Row>
      </Space>

      <CreateTripModal
        t={t}
        visible={isCreateTripModalVisible}
        isCreatingTrip={createTripMutation.isLoading}
        onCancel={closeCreateTripModal}
        onOk={createTripMutation.mutateAsync}
      />

      <FullScreenTripModal
        visible={isTripsDetailModalVisible}
        mapConfig={selectedMapConfig}
        onCancel={() => {
          setIsTripsDetailModalVisible(false);
          setSelectedMapConfig({ center: [0, 0], routes: [] });
        }}
      />

      <TripsReceiptConfirmationModal
        t={t}
        visible={isSubmitTripsModalVisible}
        isLoading={isSubmittingTrips}
        selectedTrips={selectedTrips}
        userProfile={authUser ? authUser.profile : undefined}
        onOk={submitTripsForApproval}
        onCancel={() => {
          setIsSubmitTripsModalVisible(false);
        }}
      />

      <TripsCommentsModal
        t={t}
        visible={isTripCommentsModalVisible}
        onCancel={closeTripCommentsModal}
        loading={tripCommentsQuery.isFetching}
        comments={tripCommentsQuery.data.documents}
        paginationConfig={commentsPaginationConfig}
        onPageChange={handleCommentsPageChange}
        isAddingComment={isAddingComment}
        onAddComment={addCommentToTrip}
        canAddComment={canAddCommentOnTrip(
          tripIdToViewComments ? find(trips, { _id: tripIdToViewComments }) : undefined,
        )}
      />

      <ConfirmModal
        loading={handleTripDeletion.isLoading}
        closable={!handleTripDeletion.isLoading}
        visible={isDeleteTripModalOpen}
        onCancel={closeDeleteTripModal}
        okText={t('yes')}
        cancelText={t('no')}
        title={t('deleteTrip', { count: selectedDeletableTrips.length })}
        onOk={deleteSelectedTrips}
        message={
          selectedTrips.length === selectedDeletableTrips.length ? (
            <Text variant="p" size="sm">
              {t('sureYouWantToDeleteTrips__count', { count: selectedDeletableTrips.length })}
            </Text>
          ) : (
            <Row justify="center" gutter={[5, 5]}>
              <Text variant="p" size="sm">
                {t('nonDeletableTripsWereSelected')}
              </Text>
              <Text variant="p" size="sm">
                {t('onlyPendingDeniedMoreInfoAreDeletable')}
              </Text>
              <Text variant="p" size="sm">
                {t('sureYouWantToDeleteTrips__count', { count: selectedDeletableTrips.length })}
              </Text>
            </Row>
          )
        }
      />
    </PageContainer>
  );
};

export default withNamespaces()(withAuthentication(TripsPage));
